import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
// import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { getLangs, getUrlForLang } from 'ptz-i18n';
import 'sanitize.css';

import NavTop from '../components/NavTop/NavTop'
import CallToAction01 from '../components/CallToAction_01/CallToAction_01'
import NavBottom from '../components/NavBottom/NavBottom'
import Footer from '../components/Footer/Footer'
// import Header from './header'

import './layout.scss'

// the orginal doesnt work, so use this one
const getCurLangKey = (langs, defaultLangKey, url) => {
  let langInPath = url.split('/')[1]
  let langKey = langs.find(langItem => langItem === langInPath )
  return langKey !== undefined ? langKey : defaultLangKey
}

// pass the context the children for havin different link and content for different language
export const PageLocaleContext = createContext()

const Layout = ({ children, hideHeader, hideNavBottom, hideFooter, customClass, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            languages {
              defaultLangKey
              langs
            } 
          }
        }
      }
    `}
    render={data => {
      const url = location.pathname;
      const { langs, defaultLangKey } = data.site.siteMetadata.languages;
      // const currentLangKey = getCurrentLangKey(langs, defaultLangKey, url);    // orginal one, not working if has zh-hk
      const currentLangKey = getCurLangKey(langs, defaultLangKey, url)
      const homeLink = `/${currentLangKey}/`.replace(`/${defaultLangKey}/`, '/');
      const langsMenu = getLangs(langs, currentLangKey, getUrlForLang(homeLink, url)).map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));

      

      const languageKeyInPath = currentLangKey === "en" ? "" : "/" + currentLangKey
      // const currentLangKeyForData = currentLangKey.replace('-', '_') // because we can not use "-" in object property in object in data file

      // for passing to children props
      const langProperty = {
        languageKeyInPath: languageKeyInPath,
        currentLangKey: currentLangKey
      }
      
      return (
        <PageLocaleContext.Provider value={{langProperty}} >
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: 'viewport',
                content:
                  'width=device-width, initial-scale=1.0',
              },
              {
                name: 'keywords',
                content: 'Delight voice assistant,智能家居,voice assistant,語音助手,google assistant,amazon alexa,apple homekit,HKT smart home,Smart Home Fortress,小米智能家居',
              },
            ]}
            bodyAttributes={{
              class: 'bootstrap-fs-modal',
            }}
          >
            <html lang={currentLangKey} />
            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
            <link 
              href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap" rel="stylesheet"
            />
            <link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;700&display=swap" rel="stylesheet"></link>
            <link
              rel="stylesheet"
              href="//cdnjs.cloudflare.com/ajax/libs/Swiper/4.3.5/css/swiper.min.css"
            />
            <script src="https://kit.fontawesome.com/e4f38c5fd4.js" crossOrigin="anonymous"></script>
            <script src="https://code.iconify.design/1/1.0.7/iconify.min.js"></script>
            <script async defer src="https://formfacade.com/include/109149188502387225693/form/1FAIpQLSeMHz8MIjJTUtQgKyjXTcjeOu-QL-KWRuGS8geecXcYEjX35w/classic.js?div=ff-compose"></script>
          </Helmet>
          {hideHeader ? null : <NavTop langs={langsMenu} languageKeyInPath={languageKeyInPath} currentLangKey={currentLangKey} />}
          <div className={`main-container ${customClass}`}>
            <div className="main-container-inner-01">
              <div className="main-container-inner-02">
                  {children}
              </div>
            </div>
          </div>
          {/* the landing page doesnt have CallToAcction_01 */}
          { url!=="/" && url!=="/zh-hk/" && url!=="/zh/" && <CallToAction01 url={url} languageKeyInPath={languageKeyInPath} currentLangKey={currentLangKey}/> }
          {!hideNavBottom && <NavBottom languageKeyInPath={languageKeyInPath} currentLangKey={currentLangKey}/>}
          {!hideFooter && <Footer languageKeyInPath={languageKeyInPath} currentLangKey={currentLangKey}/>}
          {/* <div id="back-to-top-button-wrapper">
            <a className="back-to-top-button" href="#top"><img src={require('../assets/img/icon/chevron-up-circle-sharp.png')} alt="back to top"></img></a>
          </div> */}
        </PageLocaleContext.Provider>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
