const footerData = {
  "en": {
    copyright: "COPYRIGHT",
    terms: "Terms & Conditions"
  },
  "zh": {
    copyright: "保留所有权利",
    terms: "服务条款及隐私"
  },
  "zh-hk": {
    copyright: "保留所有權利",
    terms: "服務條款及隱私"
  }
}

export default footerData