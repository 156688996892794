const NavBottomData = {
  "en" : {
    products: {
      heading: "Products",
      item1: 'Delight Creator',
      item2: 'Delight API',
    },
    customers: {
      heading: "Customers",
      item1: 'Mabelle', 
      item2: 'Outwhiz', 
      item3: 'IGSO', 
      item4: 'Hai Sang Hong', 
      item5: 'Buddhist Compassion', 
      item6: 'Catholic Faith',
      item7: 'Rocket Eyewear', 
      item8: 'Cocoon',
    },
    pricing: {
      heading: 'Pricing',
      item1: 'Personal',
      item2: 'Personal Pro',
      item3: 'Team',
      item4: 'Enterprise',
      item5: 'E-commerce Integration'
    },
    docs: {
      heading: 'Docs',
      item1: 'Docs',
    },
  },
  "zh" : {
    products: {
      heading: "解决方案",
      item1: 'Delight CREATOR',
      item2: 'Delight API',
    },
    customers: {
      heading: "客户",
      item1: 'Mabelle', 
      item2: 'Outwhiz', 
      item3: 'IGSO', 
      item4: '海生行', 
      item5: '温暖人间', 
      item6: '天主教教义',
      item7: 'Rocket Eyewear', 
      item8: 'Cocoon',
    },
    pricing: {
      heading: '费用方案',
      item1: '入门级',
      item2: '进阶级',
      item3: '团队',
      item4: '企业',
      item5: 'E-commerce Integration'
    },
    docs: {
      heading: '文档',
      item1: '文档',
    },
  },
  "zh-hk" : {
    products: {
      heading: "解決方案",
      item1: 'Delight CREATOR',
      item2: 'Delight API',
    },
    customers: {
      heading: "客戶",
      item1: 'Mabelle', 
      item2: 'Outwhiz', 
      item3: 'IGSO', 
      item4: '海生行', 
      item5: '温暖人間', 
      item6: '天主教教義',
      item7: 'Rocket Eyewear', 
      item8: 'Cocoon',
    },
    pricing: {
      heading: '費用方案',
      item1: '入門版',
      item2: '個人進階版',
      item3: '團隊',
      item4: '企業',
      item5: 'E-commerce Integration'
    },
    docs: {
      heading: '文檔',
      item1: '文檔',
    },
  },
  
}

export default NavBottomData