const navTopData = {
  "en": {
    link_1: {
      parent: {
        name: "Products",
      },
      children: [{
        name: "Delight Creator",
        link: "/creator/"
      }, {
        name: "Delight API",
        link: "/api/"
      }]
    },
    link_2: {
      parent: {
        name: "Customers",
        link: "/customers/"
      }
    },
    link_3: {
      parent: {
        name: "Pricing",
        id: "topnav-pricing"
      },
      children: [{
        name: "Delight Creator and API",
        link: "/pricing/"
      }, {
        name: "E-commerce Integration",
        link: "/pricing-ecommerce/"
      }],
    },
    link_4: {
      parent: {
        name: "Docs",
        link: "https://docs.delight.global/"
      }
    },
    contactBtn: "contact us",
    signUpBtn: "Try for free",
    logInBtn: "log in",
  },
  "zh": {
    link_1: {
      parent: {
        name: "解决方案",
      },
      children: [{
        name: "Delight Creator",
        link: "/creator/"
      }, {
        name: "Delight API",
        link: "/api/"
      }]
    },
    link_2: {
      parent: {
        name: "客户",
        link: "/customers/"
      }
    },
    link_3: {
      parent: {
        name: "费用方案",
        id: "topnav-pricing"
      },
      children: [{
        name: "Delight Creator and API",
        link: "/pricing/"
      }, {
        name: "E-commerce Integration",
        link: "/pricing-ecommerce/"
      }],
    },
    link_4: {
      parent: {
        name: "文档",
        link: "https://docs.delight.global/"
      }
    },
    contactBtn: '联系我们',
    signUpBtn: "免费使用",
    logInBtn: "登入",
  },
  "zh-hk": {
    link_1: {
      parent: {
        name: "解决方案",
      },
      children: [{
        name: "Delight Creator",
        link: "/creator/"
      }, {
        name: "Delight API",
        link: "/api/"
      }]
    },
    link_2: {
      parent: {
        name: "客戶",
        link: "/customers/"
      }
    },
    link_3: {
      parent: {
        name: "費用方案",
        id: "topnav-pricing"
      },
      children: [{
        name: "Delight Creator and API",
        link: "/pricing/"
      }, {
        name: "E-commerce Integration",
        link: "/pricing-ecommerce/"
      }],
    },
    link_4: {
      parent: {
        name: "文檔",
        link: "https://docs.delight.global/"
      }
    },
    contactBtn: '聯絡我們',
    signUpBtn: "免費使用",
    logInBtn: "登入",
  }
}

export default navTopData