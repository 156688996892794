import React from 'react'
import { Link } from 'gatsby'
import './Footer.scss'

import dataInLangs from './constants';


const getYear = () => {
  return new Date().getFullYear()
}


// Footer ----------------------
// -----------------------------
const Footer = ({ languageKeyInPath, currentLangKey }) => {
    // data in current lang
    const data = dataInLangs[currentLangKey]

    return (
      <footer className="footer section-container">
        <div className="fullwidth-section-container-01">
          <div className="fullwidth-section-container-02">
            <div className="footer-inner-wrapper">
              <div className="footer__section-left">
                <span className="copyright">
                  {data.copyright} © <span>{getYear()}</span> Delight
                </span>
                <Link className="terms" to={`${languageKeyInPath}/terms/`}>
                  {data.terms}
                </Link>
              </div>

              {/* <Link to="/commands/">
                Commands
              </Link> */}
              <div className="footer__section-right">
                {/* <a href="https://twitter.com/" target="_blank" rel="noreferrer">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                  <i className="fab fa-facebook-square"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
}

export default Footer
