import React, { useState } from 'react';
import { Link } from 'gatsby'
import './Button.scss';


// import toggleLeftBtn from '../../assets/img/icon/toggle-button-left.svg';
// import toggleRightBtn from '../../assets/img/icon/toggle-button-right.svg';

// style = "light-bg" / "dark-bg" / "white-bg"

const Button = ({ children, className, type="normal", elementStyle, text, href, icon, labelOrginal, labelToggled, imgOrginal, imgToggled, callback, override, link, toggleProps, onClickCallback }) => {
  const [toggle, setToggle] = useState(false)

  const handleClick = () => {
    setToggle(!toggle)
    callback()
  }
 
  return (
    <>
      {type==="toggleUsingState" && !href &&
        <button className={`button toggle-switcer ${className} ${elementStyle} ${override}`} onClick={ handleClick } >
          <div className="toggle-switcher-label">{ labelOrginal }</div>
            {icon && <img src={icon} alt=""/>}
            {!toggle && <img className="button-image" src={ imgOrginal } alt="not toggled"/>}
            {toggle && <img  className="button-image" src={ imgToggled } alt="toggled"/>}
          <div className="toggle-switcher-label">{ labelToggled }</div>
        </button>
      }

      {type==="toggleUsingProps" && !href &&
        <button className={`button toggle-switcer ${className} ${elementStyle} ${override}`} onClick={ handleClick } >
          <div className="toggle-switcher-label">{ labelOrginal }</div>
            {icon && <img src={icon} alt=""/>}
            {!toggleProps && <img className="button-image" src={ imgOrginal } alt="not toggled"/>}
            {toggleProps && <img  className="button-image" src={ imgToggled } alt="toggled"/>}
            {children}
          <div className="toggle-switcher-label">{ labelToggled }</div>
        </button>
      }

      {type==="normal" && href &&
        <a href={href} target="_blank" rel="noreferrer" className={`button  ${className} ${elementStyle} ${override}`}>
          {icon && <img src={icon} alt=""/>}{text}
          {children}
        </a>
      }

      {type==="normal" && link &&
        <Link to={link} className={`button  ${className} ${elementStyle} ${override}`}>
          {icon && <img src={icon} alt=""/>}{text}
          {children}
        </Link>
      }

      {type==="normal" && !href && !link &&
        <button className={`button  ${className} ${elementStyle} ${override}`}>
          {icon && <img src={icon} alt=""/>}{text}
        </button>
      }

      {type==="borderline" && href &&
        <a href={href} target="_blank" rel="noreferrer" className={`button--borderline  ${className} ${elementStyle} ${override}`}>
          <div className="inner-wrapper">
            {icon && <img src={icon} alt=""/>}
            {text}
          </div>
        </a>
      }

      {type==="borderline" && onClickCallback &&
        <button className={`button--borderline  ${className} ${elementStyle} ${override}`} onClick={onClickCallback} >
          <div className="inner-wrapper">
            {icon && <img src={icon} alt=""/>}
            {text}
          </div>
        </button>
      }
      
    </>
  )
}

export default Button