import React, { useRef } from 'react'
import { Link } from 'gatsby'

import { 
  // contactFormLink,
  loginLink, 
  signupLink, 
  // contactFormLink,
} from '../../Constants'

import ButtonLogo from '../ButtonLogo/ButtonLogo'
import Button from '../Button/Button'
import SelectLanguage from '../SelectLanguage/SelectLanguage'
import NavItemMobile from './NavItemMobile'

import DownArrowSvg from '../../assets/img/svg/downArrow'

import dataInLangs from './constants';

import './NavTop.scss'

// import Layout from './layout'

const NavTop = ({ langs=[], languageKeyInPath, currentLangKey }) => {

  // data in current lang
  const data = dataInLangs[currentLangKey]

  const navSecondary = useRef(null)


  const onClickCloseBtn = () => {
    navSecondary.current.classList.add('is-hidden')
  }
  const onClickMenuBtn = () => {
    navSecondary.current.classList.remove('is-hidden')
  }

  
  const navItemDesktop = data => {
    return data.parent.hasOwnProperty('link') // condition
      ? <li className="nav-level--1__list-item">
          {data.parent.link.startsWith("http")
            ? <a 
                className="body-01-01" 
                href={data.parent.link} // assume all external has no multi language now
              >
                {data.parent.name}
              </a>
            : <Link 
                className="body-01-01" 
                to={`${languageKeyInPath}${data.parent.link}`}
              >
                {data.parent.name}
              </Link>
          }
        </li>
      : <li className="nav-level--1__list-item" id={data.parent.id}>
          <div className="body-01-01">{data.parent.name}<DownArrowSvg/></div>
          <ul className="nav-level--2">
            {data.children.map(el => (
              <li className="nav-level--2__list-item" key={el.name}>
              {el.name.startsWith("http")
                ? <a 
                    className="body-01-01 top-nav-item-small" 
                    href={el.link} // assume all external has no multi language now
                  >
                    {el.name}
                  </a>
                : <Link 
                    className="body-01-01 top-nav-item-small" 
                    to={`${languageKeyInPath}${el.link}`}
                  >
                    {el.name}
                  </Link>

              }
              </li>
            ))}
          </ul>
        </li> 
  }

  return (
    <header className="nav-section">
      <nav className="nav-section__nav-primary">
        <ButtonLogo languageKeyInPath={languageKeyInPath} />
        <ul className="nav-level--1">
          {navItemDesktop(data.link_1)}
          {navItemDesktop(data.link_2)}
          {navItemDesktop(data.link_3)}
          {navItemDesktop(data.link_4)}
        </ul>
        <div className="button-group-right">
          <SelectLanguage langs={langs} /> 
          <Button href={loginLink} className="nav-button top-nav-btn secondary ">{data.logInBtn}</Button>
          <Button href={signupLink} className="nav-button light-bg top-nav-btn">{data.signUpBtn}</Button>
          {/* <Button href={contactFormLink} className="nav-button light-bg top-nav-btn">{data.contactBtn}</Button> */}
          <Button link={`${languageKeyInPath}/contact/`} className="nav-button light-bg top-nav-btn">{data.contactBtn}</Button>
        </div>

        <button id="nav-menu-btn" onClick={ onClickMenuBtn }>
          <img src={require("../../assets/img/icon/nav-menu-hamburger.svg")} alt=""/>
        </button>
      </nav>

      {/* appear on mobile --------------*/}
      {/* ------------------------------ */}
      <nav ref={navSecondary} className="nav-section__nav-secondary is-hidden">
        <div className="nav-section__nav-secondary__inner">
          <div className="nav-section__nav-secondary__section-top">
            <ButtonLogo />
            <SelectLanguage langs={langs} />
            <button id="nav-close-btn" onClick={ onClickCloseBtn }>
              <img src={require("../../assets/img/icon/cross.svg")} alt=""/>
            </button>
          </div>
          <div className="nav-section__nav-secondary__section-link">
            <ul className="nav-level--1">
              <NavItemMobile data={data.link_1} languageKeyInPath={languageKeyInPath}/>
              <NavItemMobile data={data.link_2} languageKeyInPath={languageKeyInPath}/>
              <NavItemMobile data={data.link_3} languageKeyInPath={languageKeyInPath}/>
              <NavItemMobile data={data.link_4} languageKeyInPath={languageKeyInPath}/>
            </ul>
          </div>

          <div className="nav-section__nav-secondary__section-bottom">
            <SelectLanguage langs={langs} />
            <Button href={loginLink} className="nav-button top-nav-btn secondary ">{data.logInBtn}</Button>
            <Button href={signupLink} className="nav-button light-bg top-nav-btn">{data.signUpBtn}</Button>
            {/* <Button href={contactFormLink} className="nav-button light-bg top-nav-btn">{data.contactBtn}</Button> */}
            <Button href={`${languageKeyInPath}/contact/`} className="nav-button light-bg top-nav-btn">{data.contactBtn}</Button>
          </div>      
        </div>
      </nav>
    </header>
  )
}

export default NavTop
