import React, { useContext } from 'react';
import { PageLocaleContext } from '../../Layouts/index';

import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import DownArrowSvg from '../../assets/img/svg/downArrow'

import './SelectLanguage.scss'

const renderLangName = curLangKey => (
  curLangKey ===  "en"
    ? "Eng"
    : curLangKey ===  "zh"
      ? "简中"
      : "繁中"
)


const SelectLanguage = (props) => {
  const { langProperty } = useContext(PageLocaleContext) 
  const curLangKey = langProperty.currentLangKey

  const links = props.langs.map(lang =>
    <li key={lang.langKey} className="body-01-01 list-item" selected={lang.selected}>
      <Link to={lang.link}>
      {renderLangName(lang.langKey)}
      </Link>
    </li>

  );

  return (
    <div className="select-language">
      <div className="select-language__current-lang body-01-01">{renderLangName(curLangKey)}<DownArrowSvg/></div>
      <ul className="select-language__lang-list">
        {links}
      </ul>
    </div>
  );
};

SelectLanguage.propTypes = {
  langs: PropTypes.array
};

export default SelectLanguage;