// export const KOTI_API_ROOT = 'http://localhost:3333'
// export const KOTI_API_ROOT = 'http://localhost:3000'
export const KOTI_API_ROOT = 'https://landing-api-v0.koti.ai'

export const loginLink = "https://dashboard.delight.global/login"

export const signupLink = "https://dashboard.delight.global/registration"

export const contactFormLink = "https://docs.google.com/forms/d/e/1FAIpQLSeMHz8MIjJTUtQgKyjXTcjeOu-QL-KWRuGS8geecXcYEjX35w/viewform?usp=sf_link"

export const docLink = "https://docs.delight.global/"

export const delightYouTubeId = "pS9ybHBzZXQ"

export const outWhizYouTubeId = "nmviRri6j3A"

export const cocoonYouTubeId = "6AfR7YuBwsg"


// link for medium articles ========
// =================================
export const rocketLink = {
  "en": "https://medium.com/state-of-voice-portal/rocket-eyewear-eyes-next-generation-sales-experiences-with-delight-78595b1e857e",
  "zh": "https://medium.com/state-of-voice-portal/rocket-eyewear-eyes-next-generation-sales-experiences-with-delight-78595b1e857e",
  "zh-hk": "https://medium.com/state-of-voice-portal/rocket-eyewear-eyes-next-generation-sales-experiences-with-delight-78595b1e857e",
}
export const igsoLink = {
  "en": "https://medium.com/state-of-voice-portal/2-igso-increases-transparency-of-food-supply-chain-with-delight-2-1074c232df98",
  "zh": "https://medium.com/state-of-voice-portal/2-igso-increases-transparency-of-food-supply-chain-with-delight-2-1074c232df98",
  "zh-hk": "https://medium.com/state-of-voice-portal/2-igso-increases-transparency-of-food-supply-chain-with-delight-2-1074c232df98",
}
export const hshLink = {
  "en": "https://medium.com/state-of-voice-portal/how-hai-sang-hong-transformed-from-traditional-wholesale-to-omnichannel-commerce-487f66d9a362",
  "zh": "https://medium.com/state-of-voice-portal-zh-hk/海生行-從傳統批發轉型到全渠道數碼營銷-8a53bc2e4da1",
  "zh-hk": "https://medium.com/state-of-voice-portal-zh-hk/海生行-從傳統批發轉型到全渠道數碼營銷-8a53bc2e4da1",
}

export const buddaLink = {
  "en": "https://medium.com/state-of-voice-portal/how-buddhist-compassion-uses-delight-to-bring-home-buddhas-teachings-of-wisdom-and-compassion-790d7c1a99f9",
  "zh": "https://medium.com/state-of-voice-portal-zh-hk/全港首個學佛語音助理-溫暖人間-推-每日佛偈-滋潤人心-c9c30f585f89",
  "zh-hk": "https://medium.com/state-of-voice-portal-zh-hk/全港首個學佛語音助理-溫暖人間-推-每日佛偈-滋潤人心-c9c30f585f89",
}

export const cfaithLink = {
  "en": "https://medium.com/state-of-voice-portal/spreading-the-catholic-faith-through-next-gen-voice-dialogue-with-delight-labs-9531347fb907",
  "zh": "https://medium.com/state-of-voice-portal/spreading-the-catholic-faith-through-next-gen-voice-dialogue-with-delight-labs-9531347fb907",
  "zh-hk": "https://medium.com/state-of-voice-portal/spreading-the-catholic-faith-through-next-gen-voice-dialogue-with-delight-labs-9531347fb907",
}

export const cocoonLink = {
  "en": "https://medium.com/state-of-voice-portal/cocoon-pioneers-virtual-office-assistant-for-the-new-normal-with-delight-54cedc7a80d1",
  "zh": "https://medium.com/state-of-voice-portal/cocoon-pioneers-virtual-office-assistant-for-the-new-normal-with-delight-54cedc7a80d1",
  "zh-hk": "https://medium.com/state-of-voice-portal/cocoon-pioneers-virtual-office-assistant-for-the-new-normal-with-delight-54cedc7a80d1",
}

export const mabelleLink = {
  "en": "https://medium.com/state-of-voice-portal/mabelle-1b544a2c9286",
  "zh": "https://medium.com/state-of-voice-portal-zh-hk/mabelle-657a7175d283",
  "zh-hk": "https://medium.com/state-of-voice-portal-zh-hk/mabelle-657a7175d283",
}

export const outwhizLink = {
  "en": "https://medium.com/state-of-voice-portal/outwhiz-2d8bae10ccb0",
  "zh": "https://medium.com/state-of-voice-portal-zh-hk/outwhiz-手機程式語音捷徑-35817be299f7 ",
  "zh-hk": "https://medium.com/state-of-voice-portal-zh-hk/outwhiz-手機程式語音捷徑-35817be299f7 ",
}

// =================================
// =================================