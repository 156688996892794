import React from 'react'
import { Link } from 'gatsby'

import ButtonLogo from '../ButtonLogo/ButtonLogo'

import dataInLangs from './constants.js'
import './NavBottom.scss'

import { rocketLink, igsoLink, hshLink, buddaLink, cfaithLink, cocoonLink, mabelleLink, outwhizLink } from '../../Constants'

const docsLink = "https://docs.delight.global/"

const NavBottomColumn = ({ heading, items }) => {
  return (
    <div className="item">
      <div className="item-heading bottom-nav-heading">{ heading }</div>
      {items.map(item => {
        return item.newTab 
          ? <a className="item-link bottom-nav-item" href={item.href} key={item.title} target="_blank" rel="noreferrer">{item.title}</a>
          : <Link className="item-link bottom-nav-item" to={item.href} key={item.title}>{item.title}</Link>
      })}
    </div>
  )
}

const NavBottom = ({ languageKeyInPath, currentLangKey }) => {
  const creatorPageLink = languageKeyInPath + "/creator"
  const apiPageLink = languageKeyInPath + "/api"
  const pricingPageLink = languageKeyInPath + "/pricing"
  // const pricingEcommercePageLink = languageKeyInPath + "/pricing-ecommerce"
  const data = dataInLangs[currentLangKey]

  return (
    <div className="nav-bottom section-container">
      <div className="section-container-inner-01">
        <div className="section-container-inner-02">
          <div className="nav-bottom-inner">
            <ButtonLogo languageKeyInPath={languageKeyInPath} />
            <div className="nav-bottom__items">
              <NavBottomColumn 
                heading = { data.products.heading }
                items = {[ { href: creatorPageLink, title: data.products.item1 },
                           { href: apiPageLink, title: data.products.item2 } ]}
              />
              {/* <NavBottomColumn 
                heading = "Solutions"
                items = {[ { href: "/", title: "Developers" },
                           { href: "/", title: "Business" } ]} 
              /> */}
              {/* <NavBottomColumn 
                heading = "Testimonials"
                items = {[ { href: "/", title: "Media" },
                           { href: "/", title: "Client" } ]}
              /> */}
              {/* <NavBottomColumn 
                heading = "Resources"
                items = {[ { href: "/", title: "About Us" },
                           { href: "/", title: "Blog" },
                           { href: "/", title: "Help Center" }]}
              /> */}
              <NavBottomColumn 
                heading = { data.customers.heading }
                items = {[ { href: mabelleLink[currentLangKey], title: data.customers.item1, newTab: true },
                           { href: outwhizLink[currentLangKey], title: data.customers.item2, newTab: true},
                           { href: igsoLink[currentLangKey], title: data.customers.item3, newTab: true },
                           { href: hshLink[currentLangKey], title: data.customers.item4, newTab: true },
                           { href: buddaLink[currentLangKey], title: data.customers.item5, newTab: true },
                           { href: cfaithLink[currentLangKey], title: data.customers.item6, newTab: true },
                           { href: rocketLink[currentLangKey], title: data.customers.item7, newTab: true },
                           { href: cocoonLink[currentLangKey], title: data.customers.item8, newTab: true }]}
              />

              <NavBottomColumn 
                heading = { data.pricing.heading }
                items = {[ { href: pricingPageLink, title: data.pricing.item1 },
                           { href: pricingPageLink, title: data.pricing.item2 }, 
                           { href: pricingPageLink, title: data.pricing.item3 },
                           { href: pricingPageLink, title: data.pricing.item4 } ]}
              />

              <NavBottomColumn 
                heading = { data.docs.heading }
                items = {[ { href: docsLink, title: data.docs.item1, newTab: true }]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavBottom
