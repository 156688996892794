import React from 'react';

import './CallToAction_01.scss';

import Button from '../../components/Button/Button';

import { contactFormLink, signupLink } from '../../Constants'

import { callToAction01Data } from './constants';

 
const CallToAction_01 = ({url, languageKeyInPath, currentLangKey}) => {
  const data = callToAction01Data[currentLangKey]

  const pathArr = url.split("/")
  const isContactPage = pathArr[pathArr.length-2] === "contact" || pathArr[pathArr.length-1] === "contact"

  return (
    <div className="call-to-action_01 section-container">
      <div className="fullwidth-section-container-01">
        <div className="fullwidth-section-container-02">
        <h2 className="header-h2">{data.title}</h2>
          <div className="button-wrapper">
            {/* if it is contact page, not to show the first button */}
            { !isContactPage && 
                //<Button href={contactFormLink} className="nav-button" text={data.button1} elementStyle="white-bg" override="override"/>
                <Button link={`${languageKeyInPath}/contact`} className="nav-button" text={data.button1} elementStyle="white-bg" override="override"/>
            }
            <Button href={signupLink} className={`nav-button ${isContactPage && "no-margin-left"}`} text={data.button2} elementStyle="light-bg" override="override" /> 
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallToAction_01
