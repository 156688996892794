export const callToAction01Data = {
  "en": {
    title: "Create a voice app in 3 minutes",
    button1: "contact sales",
    button2: "get it free"
  },
  "zh": {
    title: "立即开始设计",
    button1: "联络销售代表",
    button2: "免费使用"  
  },
  "zh-hk": {
    title: "立即開始設計您的語音App",
    button1: "聯絡銷售代表",
    button2: "免費使用"  
  },
}