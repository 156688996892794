import React, { useRef } from 'react'

import arrowMobileSvg from '../../assets/img/icon/arrow-right-mobile.svg';


const RetractableNavItem = ({data, languageKeyInPath}) => {

  // const navSecondary = useRef(null)
  const arrowExpandable = useRef(null)
  const menuItemLevel2 = useRef(null)

  const onClickExpandable = () => {
    let arrow = arrowExpandable.current.classList
    let itemLevel2 = menuItemLevel2.current.classList
    itemLevel2.contains('is-hidden')
      ? itemLevel2.remove('is-hidden')
      : itemLevel2.add('is-hidden')
    arrow.contains('is-rotate') 
      ? arrow.remove('is-rotate')
      : arrow.add('is-rotate')
  }

  const renderLink = (link, langKey) => {
    let prefix = link.startsWith('http') ? "" : langKey // assume all external link is not multilanguage site
    return prefix + link
  } 
  
  return data.parent.hasOwnProperty('link') 
    ? <li className="nav-level--1__list-item">
        <a className="body-01-01" href={renderLink(data.parent.link, languageKeyInPath)}>
          {data.parent.name}
        </a>
      </li>
    : <li className="nav-level--1__list-item">
        <div className="nav-level--1__list-item__expandable-item" role="button" tabIndex="0" onClick={ onClickExpandable } onKeyDown={ onClickExpandable } >
          <span className="body-01-01">{data.parent.name}</span>
          <img className="mobile-menu-arrow" src={arrowMobileSvg} ref={arrowExpandable} alt=""/>
        </div>
        <ul className="nav-level--2 is-hidden" ref={menuItemLevel2}>
          {data.children.map(el => (
            <li className="nav-level--2__list-item" key={el.name}>
              <a className="body-01-01" href={renderLink(el.link, languageKeyInPath)}>
                {el.name}
              </a>
            </li>
          ))}
        </ul>
      </li> 
}

export default RetractableNavItem
