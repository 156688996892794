import React from 'react'
import { Link } from 'gatsby'

import './ButtonLogo.scss'


const ButtonLogo = ({ languageKeyInPath }) => {
  return (
    <div className="button-logo">
      <Link className="button-logo__inner" to={`${languageKeyInPath}/`}>
        <img
          className="button-logo__inner__logo"
          alt="delight"
          src={require('../../assets/img/logo/delight-logo.svg')}
        />
        <img
          className="button-logo__inner__logo-text"
          alt="delight"
          src={require('../../assets/img/logo/delight-logo-text.svg')}
        />
      </Link>
    </div>

  )
}

export default ButtonLogo
